
import {Component, Watch, Vue} from 'vue-property-decorator';
import api from '@/infrastructure/api/API';
import {progress} from '@/infrastructure/script/Progress';
import {AnswerResult, ExamResult, ExamResultsGetRequest} from '@/types/content/ExamResult';
import SortColumnIcon from '@/components/atoms/SortColumnIcon.vue';
import {SortOrder, SortOrderRequest} from '@/types/sort/SortOrder';
import ToggleIcon from '@/components/atoms/ToggleIcon.vue';
import PerPagesSelector from '@/components/molecules/selector/PerPagesSelector.vue';
import Pagination from '@/components/atoms/Pagination.vue';

@Component({
  components: {
    ToggleIcon,
    SortColumnIcon,
    PerPagesSelector,
    Pagination,
  },
  filters: {
  },
})
export default class ExamResultList extends Vue {
  // data
  examResults: ExamResult[] = [];

  total: number = 0;
  nextPage: number | null = null;
  previousPage: number | null = null;

  answerAtOrder: SortOrderRequest = {
    sort: 'AnswerAt',
    order: SortOrder.DESC,
  };
  contentOrder: SortOrderRequest = {
    sort: 'ContentId',
    order: SortOrder.DESC,
  };

  request: ExamResultsGetRequest = {
    sorts: [
      this.answerAtOrder,
    ],
    page: 1,
    perPage: 10,
  };
  resultToggle: boolean = false;
  openResult: Date[] = [];

  @Watch('resultToggle')
  onChangeToggle() {
    if (this.resultToggle) {
      this.openResult = this.examResults.map((examResult) => examResult.answerAt);
    } else {
      this.openResult = [];
    }
  }

  // computed
  get isEmpty(): boolean {
    return this.examResults.length === 0;
  }

  // watch
  @Watch('request.page')
  onChangePage() {
    this.getExamResults();
  }

  @Watch('request.perPage')
  onChangePerPage() {
    this.request.page = 1;
    this.search();
  }

  // method
  async initialize(): Promise<void> {
    await this.getExamResults();
  }


  async change(request: SortOrderRequest): Promise<void> {
    this.request.sorts.filter((sort) => sort.sort !== request.sort).forEach((sort) => sort.order = SortOrder.NONE);
    if (request.order !== SortOrder.NONE) {
      this.request.sorts = [request];
    } else {
      this.request.sorts = [];
    }
    await this.getExamResults();
  }

  search(): void {
    this.request.page = 1;
    this.getExamResults();
  }

  async getExamResults(): Promise<void> {
    const getExamResults = async (): Promise<void> => {
      await api.getExamResults(this.request)
        .then((response: any) => {
          this.examResults = response.list;
          this.total = response.total;
          this.nextPage = response.nextPage;
          this.previousPage = response.previousPage;
        });
    };

    await progress(getExamResults);
  }
  changeToggle(answerAt: Date): void {
    if (this.openResult.some((id) => id === answerAt)) {
      this.openResult = this.openResult.filter((id) => id !== answerAt);
    } else {
      this.openResult.push(answerAt);
    }
  }

  isToggleOpened(answerAt: Date): boolean {
    return this.openResult.some((id) => id === answerAt);
  }

  correctCount(answerResults: AnswerResult[]): number {
    const count = answerResults.reduce((prev, item) => {
      return prev + (item.isCorrect ? 1 : 0);
    }, 0);
    return count;
  }

  // lifecycle hooks
  created(): void {
    this.initialize();
  }
}
